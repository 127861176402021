.wallpaper-wrapper {
  width:100%;
  flex-direction: column;
  background: url('../img/wallpaper.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgWrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(14 ,12 ,34 ,0.5);
  border-bottom: 1px solid #fae66e;
  display: flex;
  align-items: center;
  justify-content: center;
}
