.second-box {
  width: 50%;
  height: 100%;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.second-box:hover {
  box-shadow: 0px 0px 10px 0px #fae66e;
  transition-duration: 1s;
}

/* This container is needed to position the front and back side */
.second-box-inner {
  position: relative;
  width: 100%;
  height: 50%;
  text-align: center;
  transition: transform 1.4s;
  transform-style: preserve-3d;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
/* Do an horizontal second when you move the mouse over the second box container */
.second-box-inner-mounted {
  transform: rotateY(180deg);
}
/* Position the front and back side */
.second-box-front,
.second-box-back {
  margin-top:5%;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  max-height: 40%;
}
/* Style the back side */
.second-box-back {
  transform: rotateY(180deg);
  background: url('../img/responsive.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position:center;
  max-height: 90px;
  justify-content: center;
}
.second-box-text {
  width:100%;
  height:50%;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}
.second-box-text-headline {
  flex:1;
  text-align: center;
  font-size: 21px;
  color: white;
}

.second-box-text-content {
  flex:3;
  text-align: center;
  font-size: 15px;
  color: white;
  margin-top: 10px;
  width: 80%;
  margin-left: 10%;
}
