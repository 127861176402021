@media only screen and (min-width: 1000px) {
  .Profile-picture {
    flex:1;
    height:300px;
    background: url('../img/profilePicture.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;
  }
}
@media only screen and (max-width: 999px) {
  .Profile-picture {
    height:300px;
    background: url('../img/profilePicture.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;
    margin-bottom: 10%;
  }
}
