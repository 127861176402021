@media only screen and (min-width: 1000px) {
  .profile-pic-stats-wrapper {
    margin-top:5%;
    margin-bottom:5%;
    width:100%;
    flex-direction: row;
    display: flex;
    transition-duration: 3s;
  }
  .left-arrow-tale,
  .right-arrow-tale {
    box-shadow: 0px 0px 10px 1px #fae66e;
    width:20%;
    height:100%;
    text-align: center;
    line-height:30px;
    font-size:1vw;
    color:white;
  }
  .left-percentage,
  .right-percentage {
    width:10%;
    text-align:center;
    line-height:30px;
    font-size:1.4vw;
    font-family: 'Black Ops One', cursive;
    color:#fae66e;
  }
}
@media only screen and (max-width: 999px) {
  .profile-pic-stats-wrapper {
    margin-top:5%;
    margin-bottom:5%;
    width:100%;
    flex-direction: column;
    display: flex;
    transition-duration: 3s;
  }
  .left-arrow-tale,
  .right-arrow-tale {
    box-shadow: 0px 0px 10px 1px #fae66e;
    width:20%;
    height:100%;
    text-align: center;
    line-height:30px;
    font-size:2vw;
    color:white;
  }
  .left-percentage,
  .right-percentage {
    width:10%;
    text-align:center;
    line-height:30px;
    font-size:2vw;
    font-family: 'Black Ops One', cursive;
    color:#fae66e;
  }
}
  .left-side-wrapper,
  .right-side-wrapper {
    width:100%;
    flex:1.5;
    overflow-x: hidden;
  }
  .left-side-skill-react-js,
  .right-side-skill-react-js {
    margin-top:5px;
    flex-direction:column;
    width: 100%;
    height: 30px;
  }
  .left-fill-skill-level {
     box-shadow: 0px 0px 10px 0px #fae66e;
     height: 100%;
     background: #e0b943;
     transition-duration: 0.6s;
  }
  .right-fill-skill-level {
     box-shadow: 0px 0px 10px 0px #fae66e;
     height: 100%;
     background: #e0b943;
     transition-duration: 0.6s;
  }
  .left-side-inner-wrapper,
  .right-side-inner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    transition-duration: 1s;
  }
  .right-side-inner-wrapper {
    position:relative;
  }


  .left-arrow-tale {
    border-radius: 0px 30px 30px 0px;
  }
  .right-arrow-tale {
    border-radius: 30px 0px 0px 30px;
  }
  .left-triangle-wrapper {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 30px solid white;
    border-bottom: 15px solid transparent;
  }
  .right-triangle-wrapper {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 30px solid white;
    border-bottom: 15px solid transparent;
  }
  
  .left-body-wrapper,
  .right-body-wrapper {
    width: 80%;
    margin-top: 13px;
    height: 4px;
    background: white;
    display: flex;
    flex-direction: row;
  }
  .right-body-wrapper {
    transform: rotate(180deg);
  }
