.contact-page-outer-wrapper {
  display:flex;
  width:100%;
  height:500px;
  flex-direction: column;
}
.contact-page-inner-wrapper {
  width:100%;
  height: 500px;
  background: #fae66e;
  justify-content: center;
  flex-direction: column;
  background: #0e1422;
}
.contact-top-shape {
  clip-path: polygon(50% 100%, 100% 0, 100% 100%, 0 100%, 0 0);
  width:100%;
  height:100px;
  background:#0e1422;
}
.contact-page-header {
  margin-top:5%;
  margin-bottom:3%;
  font-size: 20px;
  text-align: center;
  color: #fae66e;
  font-size: 50px;
  font-family: 'Black Ops One', cursive;
}
.contact-page-message {
  margin-top:2%;
  margin-bottom:4%;
  font-size: 16px;
  text-align: center;
  color: white;
}
.contact-page-email-wrapper {
  margin-top:2%;
  margin-bottom:2%;
  text-align: center;
  width:100%;
}
@media only screen and (min-width: 1000px){
  input[type=text],
  input[type=email],
  textarea {
    width: 60%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    background: #0f1e31;
    border-color: #fae66e;
  }
  textarea {
    min-height:100px;
    min-width: 60%;
  }
  input[type=submit] {
    width: 60%;
    background-color: #fae66e;
    color: #0f1e31;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 999px){
  input[type=text],
  input[type=email],
  textarea {
    width: 80%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    background: #0f1e31;
    border-color: #fae66e;
  }
  textarea {
    min-height:100px;
    min-width: 80%;
  }
  input[type=submit] {
    width: 80%;
    background-color: #fae66e;
    color: #0f1e31;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}

input:focus,
textarea:focus  {
  outline: none;
  box-shadow: 0px 0px 10px 0px #fae66e;
}

input[type=submit]:hover {
  transition-duration: 1s;
  background-color: gold;
  box-shadow: 0px 0px 10px 0px #fae66e;
}
