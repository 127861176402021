@media only screen and (min-width: 1000px)  {
  .portfolio-partner-logo-header {
    margin-bottom:10%;
    margin-bottom:5%;
    text-align: center;
    color: #0f1d2f;
    font-size: 27px;
    font-family: 'Black Ops One', cursive;
  }
}
@media only screen and (max-width: 999px)  {
  .portfolio-partner-logo-header {
    margin-bottom:10%;
    margin-bottom:5%;
    text-align: center;
    color: #0f1d2f;
    font-size: 20px;
    font-family: 'Black Ops One', cursive;
  }
}
