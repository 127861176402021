.fourth-box {
  width: 50%;
  min-height: 250px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.fourth-box:hover {
  box-shadow: 0px 0px 10px 0px #fae66e;
  transition-duration: 1s;
}
/* This container is needed to position the front and back side */
.fourth-box-inner {
  position: relative;
  width: 100%;
  height: 50%;
  text-align: center;
  transition: transform 2.6s;
  transform-style: preserve-3d;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
/* Do an horizontal fourth when you move the mouse over the fourth box container */
.fourth-box-inner-mounted {
  transform: rotateY(180deg);
}
/* Position the front and back side */
.fourth-box-front,
.fourth-box-back {
  margin-top:5%;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  max-height: 40%;
}
/* Style the back side */
.fourth-box-back {
  transform: rotateY(180deg);
  background: url('../img/complex.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position:center;
  max-height: 90px;
  justify-content: center;
}
.fourth-box-text {
  width:100%;
  height:50%;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}
@media only screen and (min-width: 1000px) {
  .fourth-box-text-headline {
    flex:1;
    text-align: center;
    font-size: 2vw;
    color: white;
    font-family: 'Raleway', sans-serif;

  }
  .fourth-box-text-content {
    flex:3;
    text-align: center;
    font-size: 15px;
    color: white;
    margin-top: 10px;
    width: 80%;
    margin-left: 10%;
    font-family: 'Raleway', sans-serif;
  }
}
@media only screen and (min-width: 700px) and (max-width: 999px) {
  .fourth-box-text-headline {
    flex:1;
    text-align: center;
    font-size: 3vw;
    color: white;
    font-family: 'Raleway', sans-serif;

  }
  .fourth-box-text-content {
    flex:3;
    text-align: center;
    font-size: 1.5vw;
    color: white;
    margin-top: 10px;
    width: 80%;
    margin-left: 10%;
    font-family: 'Raleway', sans-serif;
  }
}
@media only screen and (max-width: 700px) {
  .fourth-box-text-headline {
    flex:1;
    text-align: center;
    font-size: 4vw;
    color: white;
    font-family: 'Raleway', sans-serif;

  }
  .fourth-box-text-content {
    flex:3;
    text-align: center;
    font-size: 2vw;
    color: white;
    margin-top: 10px;
    width: 80%;
    margin-left: 10%;
    font-family: 'Raleway', sans-serif;
  }
}
