.MessageDivWrapper {
  bottom:0;
  position: absolute;
  width: 320px;
  transform: translateY(-50%);
  height:60px;
}
.Home-button {
  height:100%;
  width:100%;
  background: transparent;
  border:none;
  outline:0;
}
.arrow {
  border: solid #c8b85a;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.svg-wrapper {
  text-align: center;
  filter: drop-shadow( 0 4px 2px #fae66e );

}
.shape {
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 8px;
  fill: transparent;
  stroke: #fae66e;
  border-bottom: 5px solid black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}
.text {
  text-align: center;
  font-family: 'Black Ops One', cursive;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 8px;
  color: #fff;
  position: absolute;
  width:100%;
  height:100%;
  margin-top: 14px;
}
.svg-wrapper:hover .shape {
  stroke-width: 4px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;

}
