.About-page-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (min-width: 1000px) {
  .About-header-before {
    margin-top:150px;
    margin-bottom:5%;
    text-align: center;
    font-size: 50px;
    font-family: 'Black Ops One', cursive;
    color: #fae66e;
    position:relative;
    left: -1200px;
    transition-duration: 1s;
  }
  .About-header {
    margin-top:150px;
    margin-bottom:5%;
    text-align: center;
    font-size: 50px;
    font-family: 'Black Ops One', cursive;
    color: #fae66e;
    position:relative;
    left:0;
    transition-duration: 1s;
  }
}
@media only screen and (max-width: 999px) {
  .About-header-before {
    margin-top:150px;
    margin-bottom:10%;
    text-align: center;
    font-size: 50px;
    font-family: 'Black Ops One', cursive;
    color: #fae66e;
    position:relative;
    left: -1200px;
    transition-duration: 1s;
    margin-bottom:10%;
  }
  .About-header {
    margin-top:150px;
    margin-bottom:10%;
    text-align: center;
    font-size: 50px;
    font-family: 'Black Ops One', cursive;
    color: #fae66e;
    position:relative;
    left:0;
    transition-duration: 1s;
  }
}
