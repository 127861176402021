.Message-wrapper {
  align-items: center;
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 1000px;
  transition-duration: 1s;

}

@media only screen and (min-width: 1000px) {
  .Message-wrapper .Hello,
  .Message-wrapper-after .Hello {
    font-size: calc(50px + 2vmin);
    text-shadow: 8px 8px rgba(14,20,34,0.7);
    font-family: 'Black Ops One', cursive;
    font-weight: 800;
    color: #fae66e;
    margin:0;
    padding:0;
  }
  .Message-wrapper .Message,
  .Message-wrapper-after .Message {
    font-size: calc(10px + 2vmin);
    text-shadow: 8px 8px rgba(14,20,34,0.7);
    font-family: 'Black Ops One', cursive;
    font-weight: 800;
    color: #FFF;
    max-width: 21ch;
  }
  .Message-wrapper-after {
    align-items: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    top:40%;
    height:60%;
    transition-duration: 1s;
  }
}
@media only screen and (max-width: 999px) {
  .Message-wrapper .Hello,
  .Message-wrapper-after .Hello {
    font-size: calc(40px + 2vmin);
    text-shadow: 8px 8px rgba(14,20,34,0.7);
    font-family: 'Black Ops One', cursive;
    font-weight: 800;
    color: #fae66e;
    margin:0;
    padding:0;
    margin-top:10%;
  }
  .Message-wrapper .Message,
  .Message-wrapper-after .Message {
    font-size: calc(7px + 2vmin);
    text-shadow: 8px 8px rgba(14,20,34,0.7);
    font-family: 'Black Ops One', cursive;
    font-weight: 800;
    color: #FFF;
    max-width: 21ch;
    margin-bottom: 10px;
  }
  .Message-wrapper-after {
    align-items: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    top:30%;
    height:70%;
    transition-duration: 1s;
  }
}
