.navigation-wrapper {
  width: 100%;
  height: 50px;
  position: fixed;
  z-index: 1000;
  margin-top:0;
}
.navigation-wrapper-unfixed {
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 1000;
  margin-top:0;
}
.burger-wrapper {
  text-align: center;
}
.blue {
  background: #0e1422;
}
.yellow {
  background: #fae66e;
}
.css-wrapper {
  /* background-color: #282c34; */
  width: 80%;
  height: 100%;
  margin-left: 10%;

}
.css-wrapper-before {
  /* background-color: #282c34; */
  width: 80%;
  height: 50px;
  position: fixed;
  z-index: 1000;
  transition-duration: 1s;
  margin-top:-55px;
  margin-left: 10%;
}
.css-wrapper-scrolled-to-about {
  width: 80%;
  height: 100%;
  z-index: 1000;
  margin-left: 10%;
}
.css-wrapper-scrolled-to-portfolio {
  width: 80%;
  height: 100%;
  z-index: 1000;
  margin-left: 10%;
}
.navigation-line-before {
  position:absolute;
  top:0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 0;
  height:50px;
  box-shadow: 0px 13px 10px -10px #fae66e;
  -webkit-transition: width 1s;
}
.navigation-line-after {
  position:absolute;
  top:0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height:50px;
  box-shadow: 0px 13px 10px -10px #fae66e;
  -webkit-transition: width 1s;
  z-index: 5
}
.navigation-line-negative {
  position:absolute;
  top:0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height:50px;
  box-shadow: 0px 15px 10px -10px #0f1d2f;
  -webkit-transition: width 1s;
  z-index: 5
}
.inner-wrapper {
  margin: 0 auto;
  width: 80%;
  height:100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 10;

}
.home-wrapper:hover,
.about-wrapper:hover {
  background: linear-gradient(to right, transparent 50%, rgba(250,230,110,0.7) 50%);
   background-size: 200% 100%;
   background-position: right bottom;
}
.gallery-wrapper:hover ,
.contact-wrapper:hover {
  background: linear-gradient(to left, transparent 50%, rgba(250,230,110,0.7) 50%);
   background-size: 200% 100%;
   background-position: left top;
}


.home-wrapper-negative:hover,
.about-wrapper-negative:hover {
  background: linear-gradient(to right, transparent 50%, rgba(22, 45, 74,0.6) 50%);
   background-size: 200% 100%;
   background-position: right bottom;
}
.gallery-wrapper-negative:hover ,
.contact-wrapper-negative:hover {
  background: linear-gradient(to left, transparent 50%, rgba(22, 45, 74,0.6) 50%);
   background-size: 200% 100%;
   background-position: left top;
}



.home-wrapper,
.home-wrapper-negative {
  flex:1;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: left bottom;
  transition: all .5s ease-out;
}

.home-wrapper p,
.about-wrapper p,
.gallery-wrapper p,
.contact-wrapper p {
  color: #fae66e;
  font-family: 'Black Ops One', cursive;
  font-weight: 300;
  font-size: calc(8px + 1vmin);
}
.about-wrapper,
.about-wrapper-negative {
  flex:1;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: left bottom;
  transition: all .5s ease-out;
}

.logo-wrapper-before {
  flex:0.8;
  align-items: center;
  background: url('../img/goldLogoRibonFinal.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height:240px;
  width:100%;
  margin-top: -200px;
  -webkit-transition: margin-top 1s;
  z-index: 500;
}
.logo-wrapper {
  flex:0.8;
  align-items: center;
  background: url('../img/goldLogoRibonFinal.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width:100%;
  height:240px;
  margin-top: -4vw;
  -webkit-transition: margin-top 1s;
  z-index: 500;
}
.logo-wrapper:hover {
  transition-duration: 1s;
  margin-top:-3vw;
}
.logo-wrapper p {
  color: #fae66e;
  font-family: 'Black Ops One', cursive;
  font-weight: 300;
  font-size: calc(4px + 2vmin);
}
.gallery-wrapper,
.gallery-wrapper-negative {
  flex:1;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: right bottom;
  transition: all .5s ease-out;
}

.contact-wrapper,
.contact-wrapper-negative {
  flex:1;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: right bottom;
  transition: all .5s ease-out;
}

.home-wrapper-negative p,
.about-wrapper-negative p,
.gallery-wrapper-negative p,
.contact-wrapper-negative p {
  color: #0e1422;
  font-family: 'Black Ops One', cursive;
  font-weight: 300;
  font-size: calc(8px + 1vmin);
}

.burger-side-menu {
  box-shadow: -2px -2px 6px  #fae66e;
  /* border: 1px solid #fae66e; */
  position:absolute;
  top:0;
  background-image:linear-gradient(to left , #0f1e31, #0e1523);
  /* border-color: #fae66e; */
  /* border-width:1; */
  transition-duration: 0.5s;
}

.side-menu-list {
  text-align: center;
  color: #fae66e;
  margin-bottom: 15%;
  margin-top: 15%;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 2px;
  font-weight: 600;
}
