.portfolio-wrapper {
  width:100%;
  background: #fae66e;
}
.portfolio-inner-wrapper {
  display: flex;
  width:100%;
  justify-content: center;
  flex-direction: column;
  transition-duration: 1s;
}
.portfolio-header {
  margin-top:150px;
  margin-bottom:5%;
  font-size: 20px;
  text-align: center;
  color: #0f1d2f;
  font-size: 50px;
  font-family: 'Black Ops One', cursive;

}
.videoTextContent {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  color:white;
  text-align: center;
  height:100%;
  z-Index:10;
  background-image: radial-gradient(rgba(26, 36, 59, 0.9), rgba(13, 19, 33,0.95) , rgba(13, 19, 33,1));
  transition-duration: 1s;
}
.videoTextInnerWrapper {
   margin-top: 50%;
   width: 100%;
   height:50%;
}
@media only screen and (min-width: 1000px) {
  .videoTextNameWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height:50%;
    color: #fae66e;
    font-family: 'Black Ops One', cursive;
    font-weight: 600;
    font-size: 1.5vw;
    text-align:justify;
  }
  .videoTextDescriptionWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height:50%;
    color: #fff;
    font-size:1vw;
    font-weight: 300;
    font-family: monospace;
    width:80%;
    margin-left:10%;
    text-align:justify;
  }
}
@media only screen and (min-width: 601px) and (max-width: 999px) {
  .videoTextNameWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height:50%;
    color: #fae66e;
    font-family: 'Black Ops One', cursive;
    font-weight: 600;
    font-size: 2vw;
    text-align:justify;
  }
  .videoTextDescriptionWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height:50%;
    color: #fff;
    font-size:1.5vw;
    font-weight: 300;
    font-family: monospace;
    width:80%;
    margin-left:10%;
    text-align:justify;
  }
}
@media only screen and (max-width: 600px) {
  .videoTextNameWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height:50%;
    color: #fae66e;
    font-family: 'Black Ops One', cursive;
    font-weight: 600;
    font-size: 3vw;
    text-align:justify;
  }
  .videoTextDescriptionWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height:50%;
    color: #fff;
    font-size:2.2vw;
    font-weight: 300;
    font-family: monospace;
    width:80%;
    margin-left:10%;
    text-align:justify;
  }
}
