.something-about-me-wrapper {
  width:100%;
  transition-duration: 2s;
}

@media only screen and (min-width: 1000px) {
  .something-about-us-header {
    font-size: 20px;
    text-align: center;
    color: #fae66e;
    font-size: 50px;
    font-family: 'Black Ops One', cursive;
    margin-bottom: 30px;

  }
  .something-about-us-content {
    width:70%;
    margin: 0 auto;
    color:white;
    font-size:16px;
    letter-spacing: 3px;
    text-align: center;
    line-height: 2;
    margin-bottom: 5%;
    text-align:justify;
  }
}
@media only screen and (max-width: 999px) {
  .something-about-us-header {
    text-align: center;
    color: #fae66e;
    font-size: 40px;
    font-family: 'Black Ops One', cursive;
    margin-bottom: 30px;

  }
  .something-about-us-content {
    width:80%;
    margin: 0 auto;
    color:white;
    font-size:13px;
    letter-spacing: 3px;
    text-align: center;
    line-height: 2;
    margin-bottom: 5%;
    text-align:justify;
  }
}
