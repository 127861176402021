@media only screen and (min-width: 1000px) {
  .About-list-wrapper {
    width:96%;
    height:35%;
    display:flex;
    flex-direction:row;
    opacity: 1;
    transition-duration: 2s;
    margin-left: 2%;
    margin-right:2%;
  }
  .About-First-two-elements {
    width:50%;
    height:100%;
    display:flex;
    flex-direction:row;
  }
  .About-Second-two-elements {
    width:50%;
    height:100%;
    display:flex;
    flex-direction:row;
  }
}
@media only screen and (max-width: 999px) {
  .About-list-wrapper {
    width:96%;
    display:flex;
    flex-direction:column;
    opacity: 1;
    transition-duration: 2s;
    margin-left: 2%;
    margin-right:2%;
    margin-bottom: 5%;
  }
  .About-First-two-elements {
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
  }
  .About-Second-two-elements {
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
  }
}
.About-list-wrapper-before {
  width:96%;
  height:35%;
  display:flex;
  flex-direction:row;
  opacity: 0;
  transition-duration: 2s;
  margin-left: 2%;
  margin-right:2%;
  margin-bottom: 5%;
}
